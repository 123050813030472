import {createFeatureSelector, createSelector} from "@ngrx/store";
import {IAuditState} from "./audit.state";

export const auditFeatureSelector = createFeatureSelector<IAuditState>('audit');

export const getAuditSelector = createSelector(
  auditFeatureSelector,
  state => state.audit
);

export const getAuditLoadingSelector = createSelector(
  auditFeatureSelector,
  state => state.isLoading
);

export const getAuditErrorSelector = createSelector(
  auditFeatureSelector,
  state => state.error
);

export const getBannerDownSelector = createSelector(
  auditFeatureSelector,
  state => state.isBannerDown
);
